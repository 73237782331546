import React from "react"
import { Link } from "gatsby"

// Components
import Layout from "../components/layout"
import Band from "../components/band"
import SEO from "../components/seo"

// Styles
import grid from "../shared/bootstrap-grid.module.css"

const BlogPostCard = ({ title, date, path, description }) => (
  <>
    <div className={grid.mt5}>
      <div className={`${grid.dFlex} ${grid.justifyContentBetween}`}>
        <h2>{title}</h2>
        <div style={{ fontSize: '14px', color: '#6d6d6d' }}>{date}</div>
      </div>
      <p>{description}</p>
      <Link to={path}>Read</Link>
    </div>

    <hr className={grid.mt5} />
  </>
);

export default function BlogPostTemplate({
  pageContext
}) {
  const { blogPosts } = pageContext;

  let sortedBlogPosts = blogPosts.sort((a, b) => new Date(b.date) - new Date(a.date));

  return (
    <Layout>
      <SEO title="Blog Posts" keywords={[
        `tigerface`, `systems`, `salesforce`, `products`, `lwc`,
        `lwc component testing`, `lwc nested components`,
        `lwc mock component`, `lwc jest test`, `blog`]} />

      <Band>
        <h1>Blog Posts</h1>
        <div className={`${grid.mtLg0} ${grid.mt4}`}>
          {sortedBlogPosts.map(({ title, date, path, description }) =>
            <BlogPostCard title={title} date={date} path={path} description={description} />
          )}
        </div>
      </Band>
    </Layout>
  )
}
